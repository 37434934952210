import "lazysizes";
import "picturefill";
// var $ = require('jQuery');

// スムーススクロール・ページトップ
$('a[href^="#"]').click(function () {
  const speed = 500;
  const href = $(this).attr("href");
  const target = $(href == "#" || href == "" ? "html" : href);
  const position = target.offset().top - 80;
  $("html, body").animate({ scrollTop: position }, speed, "swing");
  return false;
});

//アコーディオン
$(".js-menu__item__link").each(function () {
  $(this).on("click", function () {
    $("+.system__box,+.system__box--s,+.que__box", this).slideToggle();
    $(this).toggleClass("is-active");
    return false;
  });
});

// 募集職種 アコーディオン
$(".recruitEntry__job__item__head").each(function () {
  $(this).on("click", function () {
    console.log(this);
    $("+.recruitEntry__job__item__table", this).slideToggle();
    $(this).toggleClass("is-active");
    return false;
  });
});

//ヘッダーメニュー
$("#header__spBtn").on("click", function () {
  $("html").toggleClass("scroll-prevent");
  if ($(this).hasClass("is-active")) {
    $(this).removeClass("is-active");
    $("#header__spMenu").slideUp();
  } else {
    $(this).addClass("is-active");
    $("#header__spMenu").slideDown();
  }
});

// スクロールアニメーション
$(window).scroll(function () {
  var scroll = $(this).scrollTop();
  $(".top__eng").css({ transform: `translateX(-${scroll / 5}px)` });
  $(".specialHead__bgText").css({ transform: `translateX(-${scroll / 5}px)` });
});

function checkBreakPoint() {
  let w = $(window).width();
  if (w <= 835) {
    $(".recruitTopInterview__list").slick({
      prevArrow: `<div class="slide-arrow prev-arrow"><img src="${path}/img/common/slide-l.png" alt=""></div>`,
      nextArrow: `<div class="slide-arrow next-arrow"><img src="${path}/img/common/slide-r.png" alt=""></div>`,
      slidesToShow: 4,
      responsive: [
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: "20%",
            dots: true,
          },
        },
      ],
    });
  } else {
    // PC向け
    $(".recruitTopInterview__list.slick-initialized").slick("unslick");
  }
}
// ウインドウがリサイズする度にチェック
$(window).resize(function () {
  checkBreakPoint();
});
// 初回チェック
checkBreakPoint();

$(function () {
  $(".wpcf7-list-item-label").replaceWith(function () {
    $(this).replaceWith(`<span></span><p>` + $(this).text() + "</p>");
  });
});

// アニメーション
const scrollFunc = function () {
  const target = document.getElementsByClassName("fade");
  const position = Math.floor(window.innerHeight * 0.9);
  for (let i = 0; i < target.length; i++) {
    let offsetTop = Math.floor(target[i]?.getBoundingClientRect().top);
    if (offsetTop < position) {
      target[i].classList.add("active");
    }
    // else {
    //   target[i].classList.remove("active");
    // }
  }
};
window.addEventListener("scroll", scrollFunc, false);

const scrollRecruitFunc = function () {
  const target = document.getElementsByClassName("fade__recruit");
  const position = Math.floor(window.innerHeight * 0.9);
  for (let i = 0; i < target.length; i++) {
    let offsetTop = Math.floor(target[i]?.getBoundingClientRect().top);
    if (offsetTop < position) {
      target[i].classList.add("active");
    }
    // else {
    //   target[i].classList.remove("active");
    // }
  }
};
window.addEventListener("scroll", scrollRecruitFunc, false);
